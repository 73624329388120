// We need to register it again because of Vue instance instantiation issues

import type { EventLocationPreference } from '~/components/common/card/TeameventRequestCard.vue'

export interface UiState {
  isCartSidebarOpen: boolean
  isDateRequestSidebarOpen: boolean
  isWaitlistSidebarOpen: boolean
  isNavigationSidebarOpen: boolean
  isFederatedSearchOpen: boolean
  isEventRequestOpenedFromEventPage: boolean
  dateRequestSidebarRequestLocation: string | undefined
  dateRequestSidebarRequestPreferredLocation:
    | EventLocationPreference
    | undefined
}

export const useUiState = () => {
  if (!process.client) {
    return {
      isCartSidebarOpen: false,
      isDateRequestSidebarOpen: false,
      isEventRequestOpenedFromEventPage: false,
      isWaitlistSidebarOpen: false,
      isNavigationSidebarOpen: false,
      isFederatedSearchOpen: false,
      dateRequestSidebarRequestLocation: '',
      dateRequestSidebarRequestPreferredLocation: ref<
        EventLocationPreference | undefined
      >(undefined),
      toggleCartSidebar: () => {},
      toggleDateRequestSidebar: () => {},
      toggleWaitlistSidebar: () => {},
      toggleNavigationSidebar: () => {},
      toggleFederatedSearchOpen: () => {},
    }
  }

  const state = useState<UiState>('uiState', () => ({
    isCartSidebarOpen: false,
    isDateRequestSidebarOpen: false,
    isEventRequestOpenedFromEventPage: false,
    isWaitlistSidebarOpen: false,
    isNavigationSidebarOpen: false,
    isFederatedSearchOpen: false,
    dateRequestSidebarRequestLocation: '',
    dateRequestSidebarRequestPreferredLocation: undefined,
  }))

  // const { sendViewCart, sendDateRequestSidebarView } = useGtm()
  const isCartSidebarOpen = computed(() => state.value.isCartSidebarOpen)

  const toggleCartSidebar = (send_to_gtm: boolean = false) => {
    state.value.isCartSidebarOpen = !state.value.isCartSidebarOpen
    document.body.classList.remove('prevent-scroll')
    if (state.value.isCartSidebarOpen) {
      document.body.classList.add('prevent-scroll')
    }
    if (send_to_gtm) {
      sendViewCart()
    }
  }

  const closeCartSidebar = () => {
    state.value.isCartSidebarOpen = false
    document.body.classList.remove('prevent-scroll')
  }

  const isDateRequestSidebarOpen = computed(
    () => state.value.isDateRequestSidebarOpen
  )
  const toggleDateRequestSidebar = (
    isOpenedFromEventPage: boolean = false,
    requestLocation: string | undefined = undefined,
    wantedLocation: EventLocationPreference | undefined = undefined
  ) => {
    state.value.isDateRequestSidebarOpen = !state.value.isDateRequestSidebarOpen
    state.value.isEventRequestOpenedFromEventPage = isOpenedFromEventPage
    state.value.dateRequestSidebarRequestLocation = requestLocation
    state.value.dateRequestSidebarRequestPreferredLocation = wantedLocation

    document.body.classList.remove('prevent-scroll')
    if (state.value.isDateRequestSidebarOpen) {
      document.body.classList.add('prevent-scroll')
      sendDateRequestSidebarView(requestLocation || '')
    }
  }
  const isEventRequestOpenedFromEventPage = computed(
    () => state.value.isEventRequestOpenedFromEventPage
  )

  const isWaitlistSidebarOpen = computed(
    () => state.value.isWaitlistSidebarOpen
  )
  const toggleWaitlistSidebar = () => {
    state.value.isWaitlistSidebarOpen = !state.value.isWaitlistSidebarOpen

    document.body.classList.remove('prevent-scroll')
    if (state.value.isWaitlistSidebarOpen) {
      document.body.classList.add('prevent-scroll')
      sendWaitlistSidebarView()
    }
  }
  const isNavigationSidebarOpen = computed(
    () => state.value.isNavigationSidebarOpen
  )
  const toggleNavigationSidebar = () => {
    state.value.isNavigationSidebarOpen = !state.value.isNavigationSidebarOpen
  }

  const isFederatedSearchOpen = computed(
    () => state.value.isFederatedSearchOpen
  )
  const toggleFederatedSearchOpen = () => {
    state.value.isFederatedSearchOpen = !state.value.isFederatedSearchOpen
  }

  const dateRequestSidebarRequestLocation = computed(
    () => state.value.dateRequestSidebarRequestLocation
  )
  const dateRequestSidebarRequestPreferredLocation = computed(
    () => state.value.dateRequestSidebarRequestPreferredLocation
  )

  return {
    isCartSidebarOpen,
    isDateRequestSidebarOpen,
    isEventRequestOpenedFromEventPage,
    isWaitlistSidebarOpen,
    isNavigationSidebarOpen,
    isFederatedSearchOpen,
    dateRequestSidebarRequestLocation,
    dateRequestSidebarRequestPreferredLocation,
    toggleCartSidebar,
    toggleDateRequestSidebar,
    toggleWaitlistSidebar,
    toggleNavigationSidebar,
    toggleFederatedSearchOpen,
    closeCartSidebar,
  }
}
